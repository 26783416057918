<template>
  <div class="drvers">
    <UsersList :type="`accounts`">
      <PageTitle title="ACCOUNTS.TITLE" subtitle="ACCOUNTS.SUBTITLE">
        <Button @click="handleAddUSer">
          <span class="fs-16">{{ $t("GLOBAL.ADD_USER") }}</span>
          <img class="mx-2" src="@/assets/images/icons/add-user.svg" />
        </Button>
      </PageTitle>
    </UsersList>
  </div>
</template>
<script>
import UsersList from "@/components/Modules/Users/UsersList/index.vue"
import PageTitle from "@/components/Shared/PageTitle/index.vue"
export default {
  components: {
    UsersList,
    PageTitle
  },
  methods:{
    handleAddUSer(){
      // alert('test')
      this.$bvModal.show("add-user")
    }
  }
}
</script>